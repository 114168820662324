import { PrimaryButton } from '@rsa-digital/evo-shared-components/components/Button';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const ButtonWithMargin = styled(PrimaryButton)`
  margin: ${spacing(2)} 0;
`;

export const RichTextAfterButton = styled(RichTextWithModal)`
  margin-bottom: ${spacing(2)};
`;
